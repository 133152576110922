import React from 'react';

const Footer = () => {
  return (
    <div className="dsp-flx justify-center footer-container">
      <p className="footer-text m-0 fnt-poppins">
        Copyright © 2014 - {new Date().getFullYear()} Family Home Christian Books |{' '}
        <span>
          <a href="/privacypolicy">Privacy Policy</a>
        </span>
      </p>
    </div>
  );
};

export default Footer;
