import React from 'react';

export default function ErrorPageImage() {
  return (
    <svg width={401} height={206} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M199.442 166.217c-42.055 0-76.176 8.905-76.176 19.749 0 10.933 34.121 19.837 76.176 19.837 37.471 0 68.682-7.053 75.03-16.31.793-1.146 1.146-2.293 1.146-3.439 0-10.932-34.12-19.837-76.176-19.837Zm0 22.57c-24.246 0-43.819-2.909-43.819-6.524v-.264c0-.089 0-.089.088-.177 0 0 0-.088.089-.088 1.675-3.35 20.631-5.995 43.642-5.995 22.218 0 40.557 2.468 43.378 5.642.265.265.353.529.353.794v.088c.088 3.615-19.573 6.524-43.731 6.524ZM108.101 172.477H34.747a8.614 8.614 0 0 0-5.202 1.763L1.332 196.37c-1.323 1.058-.618 3.174 1.058 3.174h77.498c1.94 0 3.88-.794 5.29-2.116l23.717-22.924c.793-.705.264-2.027-.794-2.027Z"
        fill="#FBAF82"
      />
      <path
        d="M399.14 172.477h-73.443a8.614 8.614 0 0 0-5.202 1.763l-28.213 22.13c-1.323 1.058-.618 3.174 1.058 3.174h77.498c1.94 0 3.879-.794 5.29-2.116l23.717-22.924c.882-.705.353-2.027-.705-2.027Z"
        fill="#FC650A"
        fillOpacity={0.5}
      />
      <path
        d="M199.442 162.602c-42.055 0-76.176 8.905-76.176 19.749 0 10.933 34.121 19.838 76.176 19.838 37.471 0 68.682-7.054 75.03-16.311.793-1.146 1.146-2.293 1.146-3.439 0-10.932-34.12-19.837-76.176-19.837Zm0 22.57c-24.246 0-43.819-2.909-43.819-6.524v-.264c0-.089 0-.089.088-.177 0 0 0-.088.089-.088 1.675-3.35 20.631-5.995 43.642-5.995 22.218 0 40.557 2.468 43.378 5.642.265.265.353.529.353.794v.088c.088 3.615-19.573 6.524-43.731 6.524Z"
        fill="#FC650A"
      />
      <path
        d="M274.472 185.878c-6.348 9.257-37.471 16.311-75.03 16.311-42.055 0-76.176-8.905-76.176-19.838 0 0 13.225 15.958 73.531 15.958 51.842.088 72.738-9.698 77.675-12.431ZM242.82 177.767c-2.909-3.174-21.248-5.643-43.378-5.643-23.1 0-41.967 2.645-43.642 5.995.793-1.939 6.083-8.64 43.642-8.64 34.561 0 41.879 5.995 43.378 8.288Z"
        fill="#FC650A"
      />
      <path
        d="M108.719 172.476 93.289 62.092a2.383 2.383 0 0 0-2.38-2.028H70.102c-1.411 0-2.557 1.234-2.38 2.733l5.2 41.703c.177 1.675-1.057 3.086-2.732 3.086H46.385a2.808 2.808 0 0 1-2.822-2.645l-1.94-42.497c-.087-1.322-1.145-2.292-2.38-2.292H19.758c-1.41 0-2.468 1.146-2.38 2.469l2.645 72.649c.088 2.292 2.028 4.144 4.32 4.144h52.988l4.144 33.239h27.244v-.177ZM399.757 172.476 384.328 62.092a2.385 2.385 0 0 0-2.381-2.028H361.14c-1.411 0-2.557 1.234-2.381 2.733l5.202 41.703c.176 1.675-1.058 3.086-2.733 3.086h-23.805a2.807 2.807 0 0 1-2.821-2.645l-1.94-42.497c-.088-1.322-1.146-2.292-2.381-2.292h-19.396c-1.411 0-2.469 1.146-2.381 2.469l2.645 72.649c.088 2.292 2.028 4.144 4.32 4.144h52.989l4.143 33.239h27.156v-.177Z"
        fill="#140D2E"
      />
      <path
        d="M92.672 63.238 81.034 195.135c-.176 2.469-2.292 4.409-4.761 4.409H58.816c-2.91 0-5.114-2.557-4.761-5.379l7.318-61.804-52.283.881c-2.91 0-5.114-2.557-4.585-5.378l.177-1.146 11.99-64.274c.353-1.763 1.852-3.085 3.703-3.085H37.04c2.292 0 4.055 2.116 3.703 4.408L33.6 104.324c-.264 1.675.97 3.085 2.645 3.085h24.951l5.82-45.053c.264-1.763 1.763-3.086 3.526-3.086h18.603c2.028.089 3.703 1.94 3.527 3.968Z"
        fill="#FC650A"
      />
      <path
        d="m61.726 126.718-.441 5.643-52.283.881c-2.91 0-5.114-2.557-4.585-5.378l.177-1.146h57.132ZM92.672 63.238 81.034 195.135c-.176 2.469-2.292 4.409-4.76 4.409h-2.646L87.47 59.359h1.675c2.028 0 3.704 1.851 3.527 3.879ZM33.513 104.412c-.265 1.675.97 3.086 2.645 3.086h-5.114c-1.852 0-3.35-1.675-2.998-3.527l7.847-44.524h1.146c2.292 0 4.056 2.116 3.703 4.408l-7.23 40.557ZM383.622 63.238l-11.638 131.897c-.176 2.469-2.292 4.409-4.761 4.409h-17.457c-2.909 0-5.114-2.557-4.761-5.379l7.318-61.804-52.283.881c-2.909 0-5.114-2.557-4.585-5.378l.177-1.146 11.99-64.274c.353-1.763 1.852-3.085 3.703-3.085h16.664c2.292 0 4.056 2.116 3.703 4.408l-7.142 40.557c-.264 1.675.97 3.085 2.645 3.085h24.952l5.819-45.053c.264-1.763 1.763-3.086 3.526-3.086h18.603c2.116.089 3.792 1.94 3.527 3.968Z"
        fill="#FC650A"
      />
      <path
        d="m352.764 126.718-.441 5.643-52.283.881c-2.909 0-5.114-2.557-4.585-5.378l.177-1.146h57.132ZM383.622 63.238l-11.638 131.897c-.176 2.469-2.292 4.409-4.761 4.409h-2.645L378.42 59.359h1.675c2.116 0 3.792 1.851 3.527 3.879ZM324.55 104.412c-.264 1.675.97 3.086 2.645 3.086h-5.113c-1.852 0-3.351-1.675-2.998-3.527l7.847-44.524h1.146c2.292 0 4.056 2.116 3.703 4.408l-7.23 40.557Z"
        fill="#FC650A"
      />
      <path
        opacity={0.28}
        d="m164.616 90.129-18.074 91.693c-.97 4.937 2.204 9.61 6.524 9.61h95.749c4.232 0 7.406-4.496 6.613-9.257l-14.812-91.694c-.617-3.703-3.35-6.347-6.613-6.347h-62.774c-3.174-.089-5.907 2.468-6.613 5.995Z"
        fill="#FC650A"
      />
      <path
        d="m246.258 95.86 8.729 29.006c.176.618.793.97 1.411.706.44-.177.793-.706.617-1.235l-5.731-29.712c-.353-1.587-2.116-2.468-3.615-1.851-1.146.529-1.763 1.763-1.411 3.086ZM229.771 106.351l5.643 22.395c.088.441.529.617.882.529.352-.088.529-.441.44-.794l-3.614-22.835c-.177-1.058-1.323-1.763-2.381-1.322a1.836 1.836 0 0 0-.97 2.027ZM156.24 98.681l-7.318 22.306c-.088.441-.617.617-.969.441-.353-.176-.529-.529-.441-.882l5.29-22.835c.264-1.058 1.499-1.675 2.468-1.234.882.44 1.235 1.322.97 2.204ZM170.788 105.558l-9.522 41.438c-.089.441-.529.617-.882.529-.353-.088-.529-.441-.441-.793l7.494-41.879c.177-1.058 1.323-1.764 2.381-1.323.793.265 1.234 1.146.97 2.028ZM190.978 110.407l-4.585 30.065c-.088.441-.529.705-.881.529-.353-.088-.529-.441-.529-.794l2.468-30.329c.177-1.058 1.235-1.763 2.293-1.411.881.177 1.41 1.058 1.234 1.94ZM214.607 109.79l1.146 47.698c0 .441-.353.705-.794.705-.352-.088-.617-.352-.617-.705l-3.262-47.698c0-1.146.97-1.94 2.028-1.763.881.176 1.499.969 1.499 1.763Z"
        fill="#FBAF82"
      />
      <path d="M123.002 66.5a15.025 15.025 0 0 1-2.205-3.438l-2.027-13.578 4.672 4.32-.44 12.696Z" fill="#E79A6C" />
      <path
        d="M254.546 71.085s-2.909 6.083-9.257 12.872c-7.935 8.464-21.337 18.074-41.262 18.074-14.019 0-24.423-4.672-31.829-10.315-11.638-8.817-16.222-20.102-16.222-20.102l98.57-.53Z"
        fill="#140D2E"
      />
      <path
        d="M254.546 71.085s-2.909 6.083-9.257 12.872c-6.789 2.645-15.165 4.85-25.569 6.26-20.454 2.733-36.413 2.557-47.522 1.499-11.638-8.817-16.222-20.102-16.222-20.102l98.57-.53Z"
        fill="#140D2E"
      />
      <path
        d="M285.581 48.955s-.529 5.555-2.292 12.431c-.265.97-.706 2.116-1.411 3.175-1.852 2.997-5.378 6.26-11.109 9.345-.97.53-2.116 1.058-3.262 1.587-.882.441-1.764.794-2.733 1.235a199.435 199.435 0 0 1-4.056 1.587c-4.937 1.763-10.756 3.262-17.633 4.672-.529.089-.97.177-1.499.265-1.323.264-2.91.44-4.585.617-7.053.793-16.399 1.323-26.362 1.499-1.851 0-3.703.088-5.554.088-9.434.088-18.956-.176-27.244-.617-1.763-.088-3.526-.177-5.113-.353-4.85-.353-9.082-.882-12.167-1.499-4.497-.881-8.553-1.851-12.167-2.91-1.499-.44-2.91-.88-4.321-1.322-10.932-3.614-17.545-8.023-20.983-12.078-.529-.618-.97-1.147-1.323-1.764-.352-.529-.705-1.146-.881-1.675-.265-.617-.529-1.146-.618-1.763-.793-3.263-1.41-6.613-1.675-9.17-.352-2.91-.441-4.849-.441-4.849l83.759 6.26 83.758-4.673-.088-.088Z"
        fill="#FBAF82"
      />
      <path
        d="M285.493 48.779c0 .617-.441 1.41-1.235 2.38-2.204 2.557-7.494 6.348-16.487 9.787-1.146.44-2.38.881-3.703 1.322-.617.176-1.234.441-1.851.617-1.323.441-2.733.882-4.232 1.235a137.528 137.528 0 0 1-17.545 3.703c-1.587.264-3.263.529-5.026.705-7.406.97-15.694 1.587-24.951 1.763-1.763 0-3.615.088-5.466.088h-.089c-9.433 0-18.162-.44-26.009-1.146-1.763-.176-3.526-.352-5.202-.529-8.64-.97-16.31-2.38-22.923-3.967-1.499-.353-2.998-.794-4.408-1.146-13.137-3.615-21.777-8.112-25.745-11.815-.793-.705-1.411-1.498-1.851-2.116-.529-.793-.794-1.498-.794-2.204 0-6.26 24.687-19.573 87.373-19.573 62.687-.088 80.144 16.576 80.144 20.896Z"
        fill="#140D2E"
      />
      <path
        d="m169.201 35.025-45.847 18.78c-1.763-1.147-4.232-3.616-4.673-4.321l49.021-15.253 1.499.794ZM285.493 48.867c0 .529-.441 1.322-1.235 2.292l-47.081-16.134 1.499-.706 46.817 14.548ZM176.96 39.874l-26.186 24.775c-1.499-.353-2.998-.794-4.408-1.147l29.007-23.716 1.587.088ZM191.331 42.783l-12.432 26.45c-1.763-.176-3.526-.352-5.201-.529l16.046-26.009 1.587.088ZM240.528 67.91a86.236 86.236 0 0 1-5.026.706l-12.167-25.833 1.675-.088 15.518 25.216ZM210.463 70.38c-1.763 0-3.615.088-5.466.088h-.089l2.116-26.01 1.676-.088 1.763 26.01ZM267.683 60.946c-1.146.44-2.381.881-3.703 1.322l-31.652-22.394 1.675-.088 33.68 21.16Z"
        fill="#140D2E"
      />
      <path
        d="M210.551 85.192c-1.851 0-3.703.088-5.554.088v-14.9l5.466-.089.088 14.9ZM241.409 83.076c-1.41.264-2.909.44-4.584.617l-1.411-15.165 5.026-.705.969 15.253ZM270.681 73.818c-.97.529-2.116 1.058-3.262 1.587l-3.351-13.225 3.615-1.234 2.998 12.872ZM178.899 69.233l-1.058 15.43c-1.763-.089-3.526-.177-5.113-.353l1.058-15.606 5.113.53ZM150.774 64.649 148.306 79.9c-1.499-.44-2.91-.881-4.321-1.322l2.293-15.076 4.496 1.146ZM123.442 53.98l-1.763 10.845c-.353-.529-.705-1.146-.882-1.675-.264-.617-.529-1.146-.617-1.764-.793-3.262-1.41-6.612-1.675-9.169l.265-2.645s1.498 1.764 2.116 2.38c.529.53 2.556 2.029 2.556 2.029Z"
        fill="#FC650A"
        fillOpacity={0.78}
      />
      <path
        d="M201.205 54.95c25.954 0 46.993-5.96 46.993-13.313 0-7.352-21.039-13.313-46.993-13.313-25.953 0-46.992 5.96-46.992 13.313 0 7.353 21.039 13.313 46.992 13.313Z"
        fill="#140D2E"
      />
      <path
        d="M244.143 39.521s-3.439 3.88-11.903 7.23c-6.26 2.468-15.253 4.585-27.596 4.585-6.789 0-12.696-.618-17.633-1.587-16.488-3.175-23.629-10.052-23.629-10.052s3.615-26.802 26.185-35.883c4.585-1.852 10.051-2.998 16.399-2.998 31.035 0 38.177 38.705 38.177 38.705Z"
        fill="#FBAF82"
      />
      <path
        d="M187.011 49.748c-16.488-3.173-23.629-10.05-23.629-10.05s3.615-26.803 26.185-35.884c-4.408 3.703-8.728 11.109-9.433 23.188-.706 8.993 1.41 16.487 6.877 22.747Z"
        fill="#FC650A"
      />
      <path
        d="M244.143 39.521s-3.439 3.88-11.903 7.23c-1.322-4.938-3.35-9.346-3.35-9.346s-8.288 1.764-11.991-6.083c-3.703-7.847 5.202-13.49 5.202-13.49s-2.116-6.789-13.049-11.197c-10.932-4.408-19.661-2.821-19.661-2.821C193.976 1.962 199.442.816 205.79.816c31.211 0 38.353 38.705 38.353 38.705Z"
        fill="#FBD0B7"
      />
      <path
        d="M208.611 22.946c1.315 0 2.381-1.027 2.381-2.293 0-1.265-1.066-2.292-2.381-2.292-1.314 0-2.38 1.026-2.38 2.293 0 1.265 1.066 2.292 2.38 2.292ZM200.324 25.15c1.022 0 1.851-.79 1.851-1.763 0-.974-.829-1.764-1.851-1.764-1.023 0-1.852.79-1.852 1.764 0 .973.829 1.763 1.852 1.763ZM205.878 37.053c1.997 0 3.615-1.58 3.615-3.527 0-1.948-1.618-3.527-3.615-3.527-1.996 0-3.615 1.58-3.615 3.527 0 1.948 1.619 3.526 3.615 3.526Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}
